import React from "react";
import './Projects.css';

// img imports
import bounceBackChiroScreenshot from './img/bounceBackChiroScreenshot.png';
import webPubBucktownScreenshot from './img/webpubPortfolio.png';
import dgScreenshot from './img/dgPortfolio.png';
import chicagoRiverwalkLiveScreenshot from './img/chicagoRiverwalkLiveScreenshot.png';

const Projects = (props) => {

    return (

        <>

            <div className="projectContainer">
                <div id="projectCarouselControls" class="carousel slide" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div class="carousel-item active">
                            <a href="https://bouncebackchiro.com/" target="_blank" rel="noreferrer"><img src={bounceBackChiroScreenshot} class="d-block w-100" alt="bounce back chiropractic screenshot" className="screenshot bbScreenshot"></img></a>
                        </div>
                        <div class="carousel-item">
                            <a href="https://chicagoriverwalklive.com/" target="_blank" rel="noreferrer"><img src={chicagoRiverwalkLiveScreenshot} class="d-block w-100" alt="Chicago Riverwalk Live Screenshot" className="screenshot"></img></a>
                        </div>
                        <div class="carousel-item">
                            <a href="https://www.webpubbucktown.com/" target="_blank" rel="noreferrer"><img src={webPubBucktownScreenshot} class="d-block w-100" alt="Web Pub Chicago Screenshot" className="screenshot bbScreenshot"></img></a>
                        </div>
                        <div class="carousel-item">
                            <a href="https://dgdiscshop.com/" target="_blank" rel="noreferrer"><img src={dgScreenshot} class="d-block w-100" alt="DG disc shop screenshot" className="screenshot bbScreenshot"></img></a>
                        </div>
                    </div>
                    <button class="carousel-control-prev" type="button" data-bs-target="#projectCarouselControls" data-bs-slide="prev">
                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    </button>
                    <button class="carousel-control-next" type="button" data-bs-target="#projectCarouselControls" data-bs-slide="next">
                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    </button>
                </div>
            </div>

            <div className="mobileProjectContainer">
                <a href="https://bouncebackchiro.com/" target="_blank" rel="noreferrer"><img src={bounceBackChiroScreenshot} alt="bounce back screenshot" id="bounceBackScreenshot"/></a>
                <a href="https://chicagoriverwalklive.com/" target="_blank" rel="noreferrer"><img src={chicagoRiverwalkLiveScreenshot} alt="Chicago Riverwalk Live Screenshot" /></a>
                <a href="https://www.webpubbucktown.com/" target="_blank" rel="noreferrer"><img src={webPubBucktownScreenshot} alt="web pub screenshot" /></a>
                <a href="https://dgdiscshop.com/" target="_blank" rel="noreferrer"><img src={dgScreenshot} alt="dg disc shop screen shot" /></a>
                {/* math dolphin */}
                {/* rememberer */}
                {/* 2nd .net maui project */}
                {/* fishing for freedom */}
                {/* additional idea */}
                {/* additional idea */}
            </div>

        </>
    )
}

export default Projects;
import React from "react";
import { Link } from "react-router-dom";
import './Services.css';

// img imports
import arrow from './img/arrowPurpleFill.png';

const Services = (props) => {

    return (

        <>

        {/* Web development wrapper */}

        <div className="webDevWrapper">

            {/* Web Development Div */}

            <div className="developmentDiv">
                <h2>Web Development and Design</h2>
                <p className="devSubheader">Are you ready to get your business online?</p>
                <p className="devBody">I'm a full stack web developer with the skillset to build whatever website you are imagining. All my sites impliment reactive design elements, giving you a customized appearance for mobile and desktop versions. Once your site is built and ready to launch, I provide flexible hosting options to fit your budget.</p>
            </div>

            {/* Process wrapper */}

            <div className="processWrapper">

                <div className="stepsDiv">
                    <div className="step">
                        <div className="stepOne">
                            <h5 className="bold stepFont">Step 1</h5>
                            <h5 className="stepFont">Consult</h5>
                        </div>
                        <img src={arrow} alt="arrowPic" className="arrow"/>
                    </div>
                    <div className="step">
                        <div className="stepTwo">
                            <h5 className="bold stepFont">Step 2</h5>
                            <h5 className="stepFont">Design</h5>
                        </div>
                        <img src={arrow} alt="arrowPic" className="arrow"/>
                    </div>
                    <div className="step">
                        <div className="stepThree">
                            <h5 className="bold stepFont">Step 3</h5>
                            <h5 className="stepFont">Develop</h5>
                        </div>
                        <img src={arrow} alt="arrowPic" className="arrow"/>
                    </div>
                    <div className="step">
                        <div className="stepFour">
                            <h5 className="bold stepFont">Step 4</h5>
                            <h5 className="stepFont">Launch</h5>
                        </div>
                        <img src={arrow} alt="arrowPic" className="arrow"/>
                    </div>
                    <div className="step">
                        <div className="stepFive">
                            <h5 className="bold stepFont">Step 5</h5>
                            <h5 className="stepFont">Support</h5>
                        </div>
                    </div>
                </div>

                <div className="pricingDiv">
                    <p>Web Development Hourly Rate: Starting at $40/hr</p>
                    <p>* I estimate total cost for your website after our initial consultation. Cost is determined via estimated total hours and skillset required to complete your project.</p>
                </div>

            </div>

        </div>

        {/* Web Hosting wrapper */}

        <div className="webHostingWrapper">
            <div className="webHostingDiv">
                <h2>Web Hosting</h2>
                <p>You have your site built, now you need to find somewhere to host it online. I offer several options and will work with you to find the one that works best.</p>
            </div>
            <div className="hostingOptionsDiv">
                <div className="selfHosting">
                    <div className="hostingCategory">
                        <h4>Self Hosting</h4>
                    </div>
                    <div className="hostingDescription">
                        <p>Self hosting is the most affordable option but you should have some background knowledge or be willing to learn if you take this route. I'll advise you on hosting services and help you get your site online. Afterwards, you will be responsible for maintaining your site.</p>
                    </div>
                </div>
                <div className="standardHosting">
                    <div className="hostingCategory">
                        <h4>Standard Hosting</h4>
                        <p>Starting at $20/month</p>
                    </div>
                    <div className="hostingDescription">
                        <p>Standard hosting includes troubleshooting, email support, and basic updates. (new contact info, change address, etc...) Weekly updates totaling more than 30 minutes will be charged based off my hourly rate of $40/hr.</p>
                    </div>
                </div>
                <div className="hostingEditing">
                    <div className="hostingCategory">
                        <h4>Hosting and Editing Package</h4>
                        <p>Starting at $50/month</p>
                    </div>
                    <div className="hostingDescription">
                        <p>The hosting and editing package includes standard hosting plus additional weekly editing hours. Weekly updates totaling more than agreed upon limits will be charged based off my hourly rate of $40/hr.</p>
                    </div>
                </div>
            </div>
        </div>


        </>
    )
}

export default Services;
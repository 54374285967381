import React from 'react';
import './App.css';
import {
  BrowserRouter,
  Routes,
  Route
} from 'react-router-dom';

import Home from './components/Home';
import Projects from './components/Projects';
import Skills from './components/Skills';
import Contact from './components/Contact';
import Navbar from './components/Navbar';
import Services from './components/Services';
import Subscribe from './components/Subscribe';

function App() {

  return (
    <BrowserRouter>
      <Navbar/>
      <div className="App">
        <Routes>
          <Route path ="/" element = {<Home/>}/>
          <Route path = "/projects" element = {<Projects/>}/>
          <Route path="/services" element={<Services></Services>}></Route>
          <Route path = "/skills" element = {<Skills/>}/>
          <Route path = "/contact" element = {<Contact/>}/>
          <Route path="/subscribe" element = {<Subscribe/>}/>
        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
import React from "react";
import { Link } from "react-router-dom";
import './Skills.css';

const Skills = (props) => {

    return (

        <>

            {/* Skills Wrapper */}

            <div className="skillsWrapper">

                {/* Background */}

                <div className="backgroundDiv">
                    <div className="backgroundTop"></div>
                    <div className="backgroundBottom">
                        <div class="custom-shape-divider-top-1652325985">
                            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" class="shape-fill"></path>
                            </svg>
                        </div>
                    </div>
                </div>

                {/* Skills Div */}
                <div className="skillsDiv">
                    <p>Prior to web development, I worked in the medical research field. I first transitioned to programming by learning R and SQL.</p>
                    <p>That peaked my interest and lead to me learning HTML, CSS, JavaScript, React, C#, ASP.NET and Python.</p>
                    <p>I stay up to date on a variety of languages and take pride in being a full stack developer. I also stay current on cPanel administration training in order to provide hosting services and support.</p>
                </div>

            </div>



        </>
    )
}

export default Skills;
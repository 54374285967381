// general imports

import React, { useState } from "react";
import { Link } from "react-router-dom";
import './Menu.css';

// img imports
import linkedin from './img/linkedinHamburger.png';
import github from './img/githubHamburger.png';
import stackoverflow from './img/stackoverflowHamburger.png';

const Menu = (hamburgerstatus,sethamburgerstatus) => {

    return (

        <>
        
        {/* navigation menu */}
        <div className="burgerMenu">
            <div className="burgerLinks">
                <Link to="/" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Home</Link>
                <Link to="/projects" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Projects</Link>
                <Link to="/services" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Services</Link>
                <Link to="/skills" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Skills</Link>
                <Link to="/contact" className="burgerMenuNavlink" onClick={() => sethamburgerstatus(!hamburgerstatus)}>Contact</Link>    
            </div>
        </div>
        
        </>


    )
}

export default Menu;
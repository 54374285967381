// General imports
import React, { useState } from "react";
import { Link } from "react-router-dom";
import Hamburger from 'hamburger-react';
import './Navbar.css';

// component imports
import Menu from "./Menu";

const Navbar = (props) => {

    const [hamburgerOpen, setHamburgerOpen] = useState(false);

    return (

        <>

            {/* Hamburger Menu - Only visible on mobile */}

            <div className="HamburgerMenuDiv" style={{ display: hamburgerOpen ? 'inline' : 'none' }}>
                <Menu hamburgerstatus={hamburgerOpen} sethamburgerstatus={setHamburgerOpen}/>
            </div>

            {/* Navbar */}

            <nav>
                <div className="navbar">
                    <div className="navLinks">
                        <ul className="links">
                            <li><Link to="/" className="navigationLink">Home</Link></li>
                            <li><Link to="/projects" className="navigationLink">Projects</Link></li>
                            <li><Link to="/services" className="navigationLink">Services</Link></li>
                            <li><Link to="/skills" className="navigationLink">Skills</Link></li>
                            <li><Link to="/contact" className="navigationLink">Contact</Link></li>
                        </ul>
                    </div>
                    <div className="hamburgerContainer">
                        <Hamburger color="white" duration={0.7} rounded onToggle={toggled => {
                            if (toggled) {
                                setHamburgerOpen(!hamburgerOpen)
                            } else {
                                setHamburgerOpen(!hamburgerOpen)
                            }
                        }} />
                    </div>
                </div>
            </nav>

        </>

    )
}

export default Navbar;





// Navbar that is invisible at first and then appears after a couple seconds...

// import React, { useState } from 'react';

// const NavigationBar = () => {
//     const [showNav, setShowNav] = useState(false);

//     setTimeout(() => {
//         setShowNav(true);
//     }, 2000);

//     return (
//         <div>
//             {showNav && 
//             <div>
//                 <nav>
//                     <a href="#home">Home</a>
//                     <a href="#about">About</a>
//                     <a href="#services">Services</a>
//                     <a href="#contact">Contact</a>
//                 </nav>
//             </div>
//             }
//         </div>
//     );
// };

// export default NavigationBar;

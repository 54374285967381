// General imports
import React from "react";
import './Subscribe.css';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";

const Subscribe = (props) => {

    const initialOptions = {
        "client-id": "AeU1M7U04JEu47gC0T-d2aUK07Thduwu9XaryKfJCqaLwfbnSl9Nvh69aYQWSOz-maTftXquiTI7zkAw",
        currency: "USD",
        intent: "capture",
        vault: "true"
    }

    return (

        <>

            <div id="subscriptionDiv">
                <h2>Standard Hosting: ($20/month)</h2>
                <div className="subscriptionContainer">
                    <PayPalScriptProvider options={initialOptions}>
                        <PayPalButtons style={{ layout: "vertical", color: "blue" }} />
                    </PayPalScriptProvider>
                </div>
            </div>

        </>

    )

}

export default Subscribe;
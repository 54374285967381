// General imports
import React from "react";
import './Home.css';

// img imports
import linkedLogo from './img/linkedin-logo.png';
import githubLogo from './img/GitHub_logo.png';
import stackLogo from './img/logo-stackoverflow.png';
import profilePic from './img/profilePicTransparent2.png';

const Home = (props) => {

    return (

        <>
        
            {/* Wrapper Div - wrapper used to keep objects centered */}
            <div className="wrapper">

                {/* Text Div - contains brief bio */}
                <div className="textDiv">
                    <p>Hi! My name is Alex and I'm a web developer based out of St Charles, Illinois. I enjoy building websites for small businesses and creating innovative web applications.</p>
                </div>

                {/* Image Div - contains profile pic div and icons div */}
                <div className="ImageDiv">

                    {/* Profile Pic */}
                    <img src={profilePic} alt="profile pic" id="profilePic"/>

                    {/* Icon Divs */}
                    <div className="IconsDiv">
                        <div className="icon">
                            <a href="https://www.linkedin.com/in/alexdixon87/" target="_blank" rel="noreferrer">
                                <img src={linkedLogo} id="linkedInLogo" alt="Linked In Logo"></img>
                            </a>
                        </div>
                        <div className="icon">
                            <a href="https://github.com/adixie" target="_blank" rel="noreferrer" style={{marginLeft:"3rem",marginTop:"1rem"}}>
                                <img src={githubLogo} id="GitHubLogo" alt="GitHub Logo"></img>
                            </a>
                        </div>
                        <div className="icon">
                            <a href="https://stackexchange.com/users/21580396/alex" target="_blank" rel="noreferrer">
                                <img src={stackLogo} id="stackOverflowLogo" alt="Stack Overflow Logo"></img>
                            </a>
                        </div>
                    </div>

                </div>

            </div>
        
        </>
    )
}

export default Home;